import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { alertService } from '../../services/alert.service';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import Confirm from '../Confirm';
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import ReactQuill from 'react-quill';
import { stepTypeLabels } from '../../utils/humanReadableLabels';
import userStore from '../../stores/UserStore';
import { statusPill } from '../../utils/pillHelper';
import { statusLabels } from '../../utils/humanReadableLabels';

function PracticeForm() {
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { isSubmitting, isDirty, errors },
	} = useForm({
		defaultValues: {
			accessRole: null,
		},
	});

	const [authors, setAuthors] = useState([]);
	const [practiceTypes, setPracticeTypes] = useState([]);
	const { practiceId } = useParams();
	const [practice, setPractice] = useState(null);
	const [unmonitoredFieldChanged, setUnmonitoredFieldChanged] = useState(false);
	const [successMessage, setSuccessMessage] = useState('');
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [duplicateConfirm, setDuplicateConfirm] = useState(false);

	let navigate = useNavigate();

	useEffect(() => {
		if (!authors.length) {
			fetchAuthors();
		}

		if (!practiceTypes.length) {
			fetchPracticeTypes();
		}

		if (practiceId && !practice && authors.length && practiceTypes.length && orgsStore.orgs.length) {
			fetchPractice(practiceId);
		}

		setSuccessMessage(practiceId ? 'Practice updated!' : 'Practice created!');
	}, [authors, practiceTypes, practice, orgsStore.currentOrg, deleteConfirm, duplicateConfirm]);
	// let tier = orgsStore.getOrgTier();

	const fetchAuthors = async () => {
		const authorsResponse = await tetherApi.getAuthors();

		setAuthors(authorsResponse.data);
	};

	const fetchPracticeTypes = async () => {
		const practiceTypesResponse = await tetherApi.getPracticeTypes();

		setPracticeTypes(practiceTypesResponse.data);
	};

	const fetchPractice = async (practiceId) => {
		const practiceResponse = await tetherApi.getPractice(practiceId);

		setPractice(practiceResponse.data);

		reset(practiceResponse.data);
	};

	const renderIsPublic = () => {
		if (orgsStore.currentOrg?.isPublisher === true) {
			return (
				<div className="col-span-full">
					<div className="relative flex gap-x-3">
						<div className="flex h-6 items-center">
							<input
								{...register('isPublic')}
								type="checkbox"
								className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
							/>
						</div>
						<div className="text-sm leading-6">
							<label htmlFor="candidates" className="font-medium text-gray-900">
								Public
							</label>
							<p className="text-gray-500">Publish to global content library</p>
						</div>
					</div>
				</div>
			);
		}
	};

	const renderIsTrending = () => {
		if (userStore.user.isSuperUser === true) {
			return (
				<div className="col-span-full">
					<div className="relative flex gap-x-3">
						<div className="flex h-6 items-center">
							<input
								{...register('isTrending')}
								type="checkbox"
								className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
							/>
						</div>
						<div className="text-sm leading-6">
							<label htmlFor="candidates" className="font-medium text-gray-900">
								Trending
							</label>
							<p className="text-gray-500">Feature this content</p>
						</div>
					</div>
				</div>
			);
		}
	};

	const handleOverviewStepsChange = (content, delta, source, editor) => {
		setValue('overviewSteps', editor.getHTML());

		setPractice({ ...practice });

		setUnmonitoredFieldChanged(true);
	};

	const reducePracticeStepIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var practiceStep = practice.practiceSteps[parseInt(currentIndex)];

		await tetherApi.updatePracticeStep(practiceId, practiceStep.id, {
			index: practiceStep.index - 1,
		});

		fetchPractice(practiceId);
	};

	const increasePracticeStepIndex = async (event) => {
		const currentIndex = event.currentTarget.dataset.index;

		var practiceStep = practice.practiceSteps[parseInt(currentIndex)];

		await tetherApi.updatePracticeStep(practiceId, practiceStep.id, {
			index: practiceStep.index + 1,
		});

		fetchPractice(practiceId);
	};

	const onSubmit = async (data) => {
		const result = await submitPractice(data);

		if (result) {
			alertService.success(successMessage);

			navigate('/practices');
		}
	};

	const submitPractice = async (data) => {
		const formData = new FormData(document.getElementById('practice-form'));

		if (
			getValues('overviewSteps') !== null &&
			getValues('overviewSteps') !== undefined &&
			getValues('overviewSteps') !== ''
		) {
			formData.append('overviewSteps', getValues('overviewSteps'));
		}

		if (data.image[0]) {
			formData.append('image', data.image[0]);
		}

		if (data.secondaryImage[0]) {
			formData.append('secondaryImage', data.secondaryImage[0]);
		}

		formData.append('isFeatured', data.isFeatured);
		formData.append('isTrending', data.isTrending);
		formData.append('isMembersOnly', data.isMembersOnly);

		if (data.id) {
			return await tetherApi.updatePractice(data.id, formData);
		} else {
			return await tetherApi.createPractice(formData);
		}
	};

	const deletePractice = async () => {
		const result = await tetherApi.deletePractice(practiceId);

		if (result) {
			alertService.success('Practice deleted');

			navigate('/practices');
		}
	};

	const duplicatePractice = async () => {
		const formData = new FormData(document.getElementById('practice-form'));
		const result = await tetherApi.duplicatePractice(practiceId, formData);

		if (result) {
			alertService.success('Practice duplicated');

			navigate('/practices');
		}
	};

	return (
		<form className="p-12 space-y-8" id="practice-form" onSubmit={handleSubmit(onSubmit)}>
			<div className="space-y-8 divide-y divide-gray-200  sm:space-y-5">
				<div>
					<div>
						<h3 className="text-3xl font-semibold  text-gray-900 ">{practiceId ? '' : 'New Practice'}</h3>
					</div>
					<div className="flex flex-row justify-between items-center w-full">
						<div>
							<span
								className={
									'inline-flex items-center rounded-md px-2.5 py-0.5 mb-2 text-sm font-medium ' +
									statusPill(practice?.status)
								}
							>
								{statusLabels[practice?.status] || ''}
							</span>
							<h3 className="text-3xl font-semibold text-gray-900 max-w-2xl truncate">
								{practice?.title}
							</h3>
						</div>
						<div>
							{!!practice?.webUrl && (
								<div>
									<button
										type="button"
										onClick={() => setDuplicateConfirm(true)}
										className={
											practiceId
												? 'justify-center py-2 px-4 text-sm font-medium rounded-md text-neutral-800 bg-white shadow-sm border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
												: 'hidden'
										}
									>
										Duplicate
									</button>
									<button
										type="button"
										onClick={() => navigator.clipboard.writeText(practice?.webUrl)}
										className="cursor-pointer disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
									>
										Copy URL
									</button>
								</div>
							)}
						</div>
					</div>
					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-16">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Access</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select whether to publish or keep the content private and only access if you have the
								link.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
									Status*
								</label>
								<div className="mt-2">
									<select
										{...register('status', { required: 'Status is required' })}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
										onChange={() => setUnmonitoredFieldChanged(true)}
									>
										<option key="status-draft" value="draft">
											Draft
										</option>
										{/* <option key="status-published" value="published" disabled={tier === 'basic'}> */}
										<option key="status-published" value="published">
											Published
										</option>
										<option key="status-published-private" value="published-private">
											Private
										</option>
									</select>
									<p
										className={errors.status?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="status-error"
									>
										{errors.status?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="accessRole"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									User Role
								</label>
								<div className="mt-2">
									<select
										{...register('accessRole')}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md font-regular"
									>
										<option key="access-role-all" value="">
											All Members
										</option>
										<option key="access-role-org-owner" value="org_owner">
											Staff Admins
										</option>
										<option key="access-role-group-leader" value="group_leader">
											Group Admins
										</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Details</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the name, description, and author.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
									Name*
								</label>
								<div className="mt-2">
									<input
										{...register('title', {
											required: 'Title is required',
											maxLength: 'Title must be 255 characters or less',
										})}
										type="text"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={errors.title?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="title-error"
									>
										{errors.title?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="subtitle" className="block text-sm font-medium leading-6 text-gray-900">
									Subtitle
								</label>
								<div className="mt-2">
									<input
										{...register('subtitle')}
										type="text"
										name="subtitle"
										id="subtitle"
										placeholder="A short description of the practice"
										className="block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="overview" className="block text-sm font-medium leading-6 text-gray-900">
									Overview
								</label>
								<div className="mt-2">
									<textarea
										{...register('overview')}
										rows={3}
										className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300  rounded-md   font-regular"
										placeholder="A short overview of the practice"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Use this for practices with steps
									</p>
									<p
										className={errors.overview?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="overview-error"
									>
										{errors.overview?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="overviewSteps"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Overview Steps*
								</label>
								<div className="mt-2 ">
									<ReactQuill
										value={getValues('overviewSteps') || ''}
										onChange={handleOverviewStepsChange}
										modules={{
											toolbar: [
												[{ header: [false, 1, 2, 3] }],
												['bold', 'italic', 'underline'],
												[
													'link',
													'blockquote',
													{ list: 'ordered' },
													{ list: 'bullet' },
													{ indent: '-1' },
													{ indent: '+1' },
												],
												[{ align: ['', 'center', 'right'] }],
											],
										}}
										formats={[
											'header',
											'bold',
											'italic',
											'link',
											'underline',
											'script',
											'blockquote',
											'list',
											'align',
											'indent',
										]}
										name="html"
										rows="10"
										className="w-full h-80 overflow-scroll shadow-sm block focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-200  rounded-md  font-regular"
										theme="snow"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Use this for practices without steps
									</p>
									<p
										className={
											errors.overviewSteps?.message
												? 'mt-2 text-sm text-red-600 font-regular'
												: 'hidden'
										}
										id="overviewSteps-error"
									>
										{errors.overviewSteps?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="authorId" className="block text-sm font-medium leading-6 text-gray-900">
									Author*
								</label>
								<div className="mt-2">
									<select
										{...register('authorId', { required: 'Author is required' })}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="author-blank" value="">
											Select an Author...
										</option>
										{authors.map((author) => (
											<option key={`author-${author.id}`} value={author.id}>
												{author.name}
											</option>
										))}
									</select>
									<p
										className={errors.author?.message ? 'mt-2 text-sm text-red-600' : 'hidden'}
										id="author-error"
									>
										{errors.author?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Brand</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Edit the brand details like image, category, and type.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Rectangle Image - (1800 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('image')}
										type="file"
										name="image"
										id="image"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>

									<div className={practice?.imageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={practice?.imageUrl}
											alt=""
											className={practice?.imageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label htmlFor="image" className="block text-sm font-medium leading-6 text-gray-900">
									Square Image - (1080 x 1080)
								</label>
								<div className="mt-2">
									<input
										{...register('secondaryImage')}
										type="file"
										name="secondaryImage"
										id="secondaryImage"
										accept=".png,.jpg,.jpeg"
										className="block file:px-3 file:py-1.5 file:rounded file:bg-gray-50  file:border file:border-gray-300  file:text-gray-700  file:mr-4 max-w-lg block w-full border border-solid border-gray-300  rounded-md focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm   font-regular"
									/>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										Accepts .png, .jpg, .jpeg
									</p>

									<div className={practice?.secondaryImageUrl ? 'max-w-lg flex mt-5 w-80' : 'hidden'}>
										<img
											src={practice?.secondaryImageUrl}
											alt=""
											className={practice?.secondaryImageUrl ? '' : 'hidden'}
										/>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="practiceType"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Category*
								</label>
								<div className="mt-2">
									<select
										{...register('practiceType', {
											required: 'Type is required',
										})}
										className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									>
										<option key="practice-type-blank" value="">
											Select a Category...
										</option>
										{practiceTypes.map((practiceType) => (
											<option key={`practiceType-${practiceType.key}`} value={practiceType.key}>
												{practiceType.label}
											</option>
										))}
									</select>
									<p className="mt-2 text-sm text-gray-500  font-regular">
										This label organizes and describes the practice to users.
									</p>
									<p
										className={
											errors.practiceType?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="practice-type-error"
									>
										{errors.practiceType?.message}
									</p>
								</div>
							</div>
							<div className="col-span-full">
								<label
									htmlFor="durationInMinutes"
									className="block text-sm font-medium leading-6 text-gray-900"
								>
									Time*
								</label>
								<div className="mt-2">
									<input
										{...register('durationInMinutes', {
											required: 'Duration In Minutes is required',
											min: 1,
											max: 1440,
										})}
										type="text"
										placeholder="5"
										className="max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:max-w-xs sm:text-sm border-gray-300  rounded-md   font-regular"
									/>
									<p
										className={
											errors.durationInMinutes?.message ? 'mt-2 text-sm text-red-600' : 'hidden'
										}
										id="duration-in-minutes-error"
									>
										{errors.durationInMinutes?.message}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 mt-8">
						<div>
							<h2 className="text-base font-semibold leading-7 text-gray-900">Options</h2>
							<p className="mt-1 text-sm leading-6 text-gray-600">
								Select from a range of options including whether to feature or restrict content.
							</p>
						</div>
						<div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
							<div className="col-span-full">{renderIsPublic()}</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isFeatured')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Featured
										</label>
										<p className="text-gray-500">Feature this content</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">
								<div className="relative flex gap-x-3">
									<div className="flex h-6 items-center">
										<input
											{...register('isMembersOnly')}
											type="checkbox"
											className="h-4 w-4 rounded border-gray-300 text-neutral-800 focus:ring-neutral-800"
										/>
									</div>
									<div className="text-sm leading-6">
										<label htmlFor="candidates" className="font-medium text-gray-900">
											Members Only
										</label>
										<p className="text-gray-500">Restrict to users with accounts</p>
									</div>
								</div>
							</div>
							<div className="col-span-full">{renderIsTrending()}</div>
						</div>
					</div>
				</div>
			</div>

			<div className="pt-5">
				<div className="flex justify-end">
					<button
						type="button"
						onClick={() => setDeleteConfirm(true)}
						className={
							practiceId
								? 'mr-3 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
								: 'hidden'
						}
					>
						Delete
					</button>

					<Link
						to="/practices"
						type="button"
						className={
							isSubmitting
								? 'hidden'
								: 'bg-white  py-2 px-4 border border-gray-300  rounded-md shadow-sm text-sm font-medium text-gray-700  hover:bg-gray-50   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
						}
					>
						Cancel
					</Link>
					{isDirty || unmonitoredFieldChanged || !practiceId ? (
						<button
							type="submit"
							disabled={(!isDirty && !unmonitoredFieldChanged) || isSubmitting}
							className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						>
							Save
						</button>
					) : practice?.webUrl ? (
						<div
							onClick={() => window.open(practice.webUrl)}
							className="disabled:opacity-25 ml-3 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
						>
							{practice?.status === 'draft' ? 'Preview' : 'Visit'}
						</div>
					) : (
						<div />
					)}
				</div>
			</div>

			<div className={practiceId ? '' : 'hidden'}>
				<div className="mt-16 mb-4 space-y-8 divide-y divide-gray-200  sm:space-y-5">
					<div className="sm:flex sm:items-center">
						<div className="sm:flex-auto">
							<h1 className="text-2xl font-semibold text-gray-900 ">All Steps</h1>
						</div>
						<div className="mt-0 sm:mt-0 sm:ml-16 sm:flex-none">
							<Link
								to={`/practices/${practice?.id}/practice_steps/new`}
								className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-900"
							>
								New Step
							</Link>
						</div>
					</div>
				</div>

				<div className="">
					<div className="mt-8 flow-root">
						<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
								{practice?.practiceSteps?.length === 0 ? (
									<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
										<div className="text-center">
											<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
												No steps to display
											</p>
											<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
												No steps are available.
											</p>
											<Link
												to={`/practices/${practice?.id}/practice_steps/new`}
												className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
											>
												New Step
											</Link>
										</div>
									</div>
								) : (
									<table className="min-w-full divide-y divide-gray-300">
										<thead className="bg-white">
											<tr>
												<th
													scope="col"
													className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
												>
													Order
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Type
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Name
												</th>
												<th
													scope="col"
													className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
												>
													Content
												</th>
												<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
													<span className="sr-only">Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{practice?.practiceSteps?.map((practiceStep, i) => (
												<tr
													key={practiceStep.id}
													className={i % 2 === 0 ? undefined : 'bg-white'}
												>
													<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 truncate">
														<ArrowUpCircleIcon
															data-index={i}
															className={
																i === 0
																	? 'opacity-25 inline-block h-5 w-5 mr-4'
																	: 'cursor-pointer inline-block h-5 w-5 mr-4'
															}
															onClick={i === 0 ? null : reducePracticeStepIndex}
														/>
														<ArrowDownCircleIcon
															data-index={i}
															className={
																i === practice?.practiceSteps?.length - 1
																	? 'opacity-25 inline-block h-5 w-5 mr-4'
																	: 'cursor-pointer inline-block h-5 w-5 mr-4'
															}
															onClick={
																i === practice?.practiceSteps?.length - 1
																	? null
																	: increasePracticeStepIndex
															}
														/>
													</td>
													<td className="whitespace-nowrap py-4 px-3 pr-3 text-sm font-medium text-gray-900 max-w-xs truncate">
														{stepTypeLabels[practiceStep.stepType]}
													</td>
													<td className="whitespace-nowrap py-4 px-3 pr-3 text-sm font-medium text-gray-900 max-w-xs truncate">
														{practiceStep.title || practiceStep.subtitle}
													</td>
													<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-sm truncate font-regular">
														{practiceStep.body?.replace(/<\/?[^>]+(>|$)/g, '')}
													</td>
													<td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
														<Link
															to={`/practices/${practiceStep.practiceId}/practice_steps/${practiceStep.id}`}
															className="text-neutral-800 hover:text-neutral-900"
														>
															Edit
															<span className="sr-only">, {practiceStep.title}</span>
														</Link>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Confirm
				isOpen={deleteConfirm}
				title="Delete Practice"
				body="This will delete all content for the Practice and cannot be undone. Are you sure?"
				onConfirm={deletePractice}
				onCancel={() => setDeleteConfirm(false)}
			/>
			<Confirm
				isOpen={duplicateConfirm}
				title="Duplicate Practice"
				body="This will duplicate all content and create a new Practice. Are you sure?"
				onConfirm={duplicatePractice}
				onCancel={() => setDuplicateConfirm(false)}
			/>
		</form>
	);
}

export default observer(PracticeForm);
