import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import orgsStore from '../../stores/OrgsStore';
import tetherApi from '../../api/tether';
import { Link } from 'react-router-dom';
import { statusPill } from '../../utils/pillHelper';
import moment from 'moment';
import Pagination from '../Pagination';
import { statusLabels, guideLabels } from '../../utils/humanReadableLabels';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

function PathwaysList() {
	const [page, setPage] = useState(1);
	const [perPage] = useState(25);
	const [total, setTotal] = useState(0);
	const [numPages, setNumPages] = useState(1);
	const [startIndex, setStartIndex] = useState(0);
	const [endIndex, setEndIndex] = useState(0);
	const [pathways, setPathways] = useState([]);
	const [sortBy, setSortBy] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		if (orgsStore.orgs.length) {
			fetchPathways();
		}
	}, [page, orgsStore.currentOrg]);

	useEffect(() => {
		fetchPathways();
	}, [sortBy, sortOrder]);

	const fetchPathways = async () => {
		const opts = {};
		if (sortBy) {
			opts.sortBy = sortBy;
		}
		if (sortOrder) {
			opts.sortOrder = sortOrder;
		}
		const pathwaysResponse = await tetherApi.getPathways(page, perPage, opts);

		setPathways(pathwaysResponse.data);

		const totalCount = pathwaysResponse.total;
		const pagesCount = Math.ceil(totalCount / perPage);

		setTotal(totalCount);
		setNumPages(pagesCount);
		setStartIndex(totalCount > 0 ? perPage * (page - 1) + 1 : 0);
		setEndIndex(perPage * (page - 1) + pathwaysResponse.data.length);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-12">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-2xl font-semibold text-black xs:pt-6 xs:pb-4 lg:pt-0 lg:pb-0">Plans</h1>
					{/* <h3 className="font-regular text-gray-500">
						Simple courses with beautifully structured content
					</h3> */}
				</div>
				<div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
					<Link
						to="https://support.withtether.com/en/articles/9535080-about-plans"
						target="_blank"
						className="mr-3 inline-flex items-center justify-center rounded-lg border bg-white px-4 py-2 text-sm font-medium shadow-sm"
					>
						Learn More
					</Link>
					<Link
						to="/plans/new"
						className="inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
					>
						New Plan
					</Link>
				</div>
			</div>
			{/* <IntroductionCard
				localStorageKey="hidePlanVideo"
				vimeoLink="https://player.vimeo.com/video/858737596?h=85b925ce3d"
				bullets={[
					'Common use cases include sermon series, group studies, class content, devotionals, training materials, and reading plans.',
					// eslint-disable-next-line quotes
					"Users always have the option to share their responses and activity with specific groups they're a part of, or keep their responses private.",
					// eslint-disable-next-line quotes
					"Group Admins can start a Plan for their group, so it shows up in everyone's daily goal.",
				]}
				type="Plans"
			/> */}
			<div className="">
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							{pathways.length === 0 ? (
								<div className="flex h-72 items-center justify-center rounded-tremor-small border border-tremor-border bg-tremor-background-muted dark:border-dark-tremor-border dark:bg-dark-tremor-background-muted">
									<div className="text-center">
										<p className="mt-2 font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
											No plans to display
										</p>
										<p className="mt-1 text-tremor-default text-tremor-content dark:text-dark-tremor-content">
											No plans are available.
										</p>
										<Link
											to="/plans/new"
											className="mt-4 inline-flex items-center justify-center rounded-lg border text-white px-4 py-2 text-sm font-medium shadow-sm bg-neutral-800"
										>
											New Plan
										</Link>
									</div>
								</div>
							) : (
								<table className="min-w-full divide-y divide-gray-300">
									<thead className="bg-white">
										<tr>
											<th
												scope="col"
												className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'name') {
														setSortBy('name');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Name
													{sortBy === 'name' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'status') {
														setSortBy('status');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Status
													{sortBy === 'status' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
											>
												Author
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'mode') {
														setSortBy('mode');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Type
													{sortBy === 'mode' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'featured') {
														setSortBy('featured');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Featured
													{sortBy === 'featured' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'is_members_only') {
														setSortBy('is_members_only');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Members Only
													{sortBy === 'is_members_only' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											{/* <th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Start Date
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												End Date
											</th> */}
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 cursor-pointer"
												onClick={() => {
													if (sortBy !== 'updated_at') {
														setSortBy('updated_at');
														setSortOrder('asc');
													} else {
														if (sortOrder === 'asc') {
															setSortOrder('desc');
														} else {
															setSortOrder('asc');
														}
													}
												}}
											>
												<div className="flex flex-row">
													Last Updated
													{sortBy === 'updated_at' ? (
														<div className="pl-2">
															{sortOrder === 'asc' ? (
																<ChevronDownIcon
																	className="h-5 w-5"
																	aria-hidden="true"
																/>
															) : (
																<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
															)}
														</div>
													) : null}
												</div>
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Stats
											</th>
											<th
												scope="col"
												className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
											>
												Edit
											</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{pathways.map((pathway, pathwayIdx) => (
											<tr
												key={pathway.id}
												className={pathwayIdx % 2 === 0 ? undefined : 'bg-white'}
											>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm max-w-xs truncate font-medium text-gray-900 sm:pl-0">
													{pathway.name}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													<span
														className={
															'inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium ' +
															statusPill(pathway.status)
														}
													>
														{statusLabels[pathway.status] || 'No Status'}
													</span>
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm max-w-xs truncate text-gray-500">
													{pathway.author.name}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm max-w-xs truncate text-gray-500">
													{guideLabels[pathway.mode] || 'No Type'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{pathway.featured ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm max-w-xs truncate text-gray-500">
													{pathway.isMembersOnly ? 'Yes' : 'No'}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{moment(pathway.updatedAt).format('LL')}
												</td>
												{/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{pathway.startDate ? moment(pathway.startDate).format('LL') : ''}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{pathway.endDate ? moment(pathway.endDate).format('LL') : ''}
												</td> */}

												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/plans/${pathway.id}/stats`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Stats<span className="sr-only">, {pathway.name}</span>
													</Link>
												</td>

												<td className="relative whitespace-nowrap px-3 py-4 text-sm text-neutral-800 hover:text-neutral-900">
													<Link
														to={`/plans/${pathway.id}`}
														className="font-medium text-neutral-800 hover:text-neutral-900"
													>
														Edit<span className="sr-only">, {pathway.name}</span>
													</Link>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>

			<Pagination
				page={page}
				setPage={setPage}
				numPages={numPages}
				startIndex={startIndex}
				endIndex={endIndex}
				total={total}
			/>
		</div>
	);
}

export default observer(PathwaysList);
